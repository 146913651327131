<template>
  <van-skeleton v-if="loading" row="6" />
  <div v-else class="page">
    <div class="head">
      <div class="title">
        <p>
          <span>父母姓结合</span>
        </p>
      </div>
      <div  class="about">
        <div class="l1">
          <p>以 慕知念思惜和见遇 组合的名字</p>
        </div>
      </div>
    </div>
    <p class="tips">共找到{{num_info.all}}个谐音组合</p>
    <div class="div_fumu">
      <router-link :class="'one_name background_'+ item.color"  v-for="( item,index) in list" :key="index" :to="{name : 'name_detail', query : {name : item.word}}" >{{item.word}}</router-link>
    </div>
    <button  v-if="num_info.shengyu>0" class="to_be_vip"  data-value="name_fumu|" @click="xml_go_to_goods_index">会员可查看剩余{{num_info.shengyu}}个组合</button>
    <ul id="last_right_c" class="last_right">
      <li  @click="xml_go_to_only" data-value="index"  class="home"><img src="@/assets/img/home.png" /></li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'
import Utils from '@/utils/utils'

export default {
  name: 'name_fumu',
  data () {
    return {
      loading: true,
      list: [],
      nums: 0,
      num_info: {
        all: 0,
        shengyu: 0
      }
    }
  },
  created () {
    this.fumu_index()
  },

  methods: {
    fumu_index () {
      const data = { xing1: this.$route.query.xing1, xing2: this.$route.query.xing2 }
      axios.post('/fumu_name_index/', data)
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          this.loading = false
          this.list = res.data.data.list
          console.log(res.data.data.list)
          this.num_info = res.data.data.num_info
        })
    },
    xml_go_to_only (e) {
      Utils.go_to(e)
    },
    xml_go_to_goods_index (e) {
      Utils.go_to_goods_index(e)
    }
  }
}
</script>

<style scoped>
.div_fumu{
  text-align: left;
  margin-left: 0.5rem;
}
a.one_name{
  font-size: 0.5rem;
  display: inline-block;
  height:0.85rem;
  line-height: 0.85rem;
  padding: 0.06rem 0.4rem 0rem 0.4rem;
  text-align: center;
  border-radius: 0.8rem;
  color: #fefefe;
  margin: 0.3rem 0rem 0.3rem 0.4rem;
  box-shadow:0.02rem 0.02rem 0.1rem #888;
}

.head .title p{
  font-size: 0.8rem;
  line-height: 0.8rem;
}
</style>
